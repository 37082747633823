import React from 'react';
import { Loader, Grid, Dropdown } from 'semantic-ui-react';
import Geocode from 'react-geocode';
import _ from 'lodash';


class GeoClosestLocationSelect extends React.Component {

    static LOCATION_UNKNOWN = 0;
    static LOCATION_WAITING_FOR_USER_LOCATION = 1;
    static LOCATION_ACQUIRED_AND_CALCULATING = 2;
    static LOCATION_CLOSEST_DETERMINED = 3;
    static LOCATION_UNAVAILABLE_MUST_BE_MANUAL = 4;
    static LOCATION_CONFIRMED = 5;

    constructor(props) {
        super(props);

        Geocode.setApiKey('AIzaSyDMXxJrtC-CdCV27C_5k3GrDRcVRCPswC8');

        this.state = {
            locationState: GeoClosestLocationSelect.LOCATION_UNKNOWN,
            selectedLocation: null,
            latitude: null,
            longitude: null,
        }

        this.hasUsersLocation = this.hasUsersLocation.bind(this);
        this.doesNotHaveUsersLocation = this.doesNotHaveUsersLocation.bind(this);
        this.renderWaiting = this.renderWaiting.bind(this);
        this.renderSelectFromDropdown = this.renderSelectFromDropdown.bind(this);
        this.calcDistance = this.calcDistance.bind(this);
    }

    componentDidMount() {
        if ("geolocation" in navigator) {
            this.setState({locationState: GeoClosestLocationSelect.LOCATION_WAITING_FOR_USER_LOCATION});
            navigator.geolocation.getCurrentPosition(this.hasUsersLocation, this.doesNotHaveUsersLocation);
        } else {
            this.setState({locationState: GeoClosestLocationSelect.LOCATION_UNAVAILABLE_MUST_BE_MANUAL});
        }
    }

    async hasUsersLocation(position) {
        const { locations, onSelectLocation, maxDistanceMiles } = this.props;

        this.setState({
            locationState: GeoClosestLocationSelect.LOCATION_ACQUIRED_AND_CALCULATING,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
        });

        const userLat = position.coords.latitude;
        const userLong = position.coords.longitude;

        let closestDistance = Number.MAX_VALUE;
        let closestLocation = null;
        const addressRegex = /(.*)\((.*)\)/;

        for (let i = 0; i < locations.length; i ++) {
            const location = locations[i];
            const regexResult = location.text.match(addressRegex);
            location.name = `KFC ${regexResult[1]}`;
            location.address = regexResult[2];
            let results = null;

            try {
                results = await Geocode.fromAddress(location.address);
            } catch {
                continue;
            }

            if (results) {
                const { lat, lng } = results.results[0].geometry.location;
                const dist = this.calcDistance(lat, lng, userLat, userLong);
                location.distance = dist;
                if (dist < closestDistance) {
                  closestDistance = dist;
                  closestLocation = location;
                }
            } else {
                console.log("Geocode result is not valid!")
            }
        }

        // Since this method may take a while and the state changed since it started, here we double check.
        const { locationState } = this.state;
        if (locationState === GeoClosestLocationSelect.LOCATION_ACQUIRED_AND_CALCULATING) {
            if (closestLocation && closestDistance <= maxDistanceMiles) {
                onSelectLocation(closestLocation);
                this.setState({
                    selectedLocation: closestLocation,
                    locationState: GeoClosestLocationSelect.LOCATION_CLOSEST_DETERMINED,
                });
            } else {
                this.setState({
                    locationState: GeoClosestLocationSelect.LOCATION_UNAVAILABLE_MUST_BE_MANUAL,
                });
            }
        }
    }


    /**
     * https://stackoverflow.com/a/60465578/720175
     *
     * 'M' is statute miles (default)
     * 'K' is kilometers
     * 'N' is nautical miles
     */
    calcDistance(lat1, lon1, lat2, lon2, unit = 'M') {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1/180;
            var radlat2 = Math.PI * lat2/180;
            var theta = lon1-lon2;
            var radtheta = Math.PI * theta/180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180/Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit=="K") { dist = dist * 1.609344 }
            if (unit=="N") { dist = dist * 0.8684 }
            return dist;
        }
    }


    doesNotHaveUsersLocation(error) {
        this.setState({locationState: GeoClosestLocationSelect.LOCATION_UNAVAILABLE_MUST_BE_MANUAL});
    }


    renderWaiting(msg) {
      return (
        <Grid textAlign="center">
          <Grid.Column width="16">
            <Loader active inline>{ msg }...</Loader>
          </Grid.Column>
          <Grid.Column width="16">
            <a href="#" onClick={(e) => {
                e.preventDefault();
                this.setState({locationState: GeoClosestLocationSelect.LOCATION_UNAVAILABLE_MUST_BE_MANUAL})
            }}>... or manually select a location.</a>
          </Grid.Column>
        </Grid>
      )
    }


    renderConfirmLocation() {
        const { onSelectLocation } = this.props;
        const { selectedLocation } = this.state;

        return (
            <Grid textAlign="center">
                <Grid.Column>
                <p>We found the following restaurant just { parseInt(selectedLocation.distance) } miles from you:</p>
                <p><strong>{ selectedLocation.name }</strong><br/>{ selectedLocation.address }</p>
                <p>
                    To use this location, click Next Step. If you prefer to select a location yourself,&nbsp;
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        onSelectLocation(null);
                        this.setState({locationState: GeoClosestLocationSelect.LOCATION_UNAVAILABLE_MUST_BE_MANUAL});
                    }}>
                        click here
                    </a>.
                </p>
                </Grid.Column>
            </Grid>
        )
    }


    renderSelectFromDropdown() {
      const { locations, onSelectLocation, selectedLocationId } = this.props;

      return (
        <>
          <p>Select your preferred location:</p>
          <Dropdown
            placeholder='Select location'
            fluid
            clearable
            search
            selection
            value={selectedLocationId}
            options={locations}
            onChange={(event, data) => onSelectLocation(data)}
          />
        </>
      );
    }

    render() {
        const { locationState } = this.state;

        switch(locationState) {
            case (GeoClosestLocationSelect.LOCATION_UNKNOWN):
            case (GeoClosestLocationSelect.LOCATION_WAITING_FOR_USER_LOCATION):
            default:
                return this.renderWaiting("Loading your location");
                break;
            case (GeoClosestLocationSelect.LOCATION_ACQUIRED_AND_CALCULATING):
                return this.renderWaiting("Calculating closest location");
                break;
            case (GeoClosestLocationSelect.LOCATION_UNAVAILABLE_MUST_BE_MANUAL):
                return this.renderSelectFromDropdown();
                break;
            case (GeoClosestLocationSelect.LOCATION_CLOSEST_DETERMINED):
                return this.renderConfirmLocation();
                break;
        }

    }
}

export default GeoClosestLocationSelect;
