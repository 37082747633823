import React from 'react';
import { Icon } from 'semantic-ui-react';

const MenuCartDisplayBanner = ({
  cartDisplayTitle = 'Order Summary',
  onToggleMobileCart,
  mobileCartOpen,
  numberOfItems
}) => {


  return (
    <div className="menu-cart-banner" onClick={onToggleMobileCart}>
      <h4>{cartDisplayTitle}</h4>
      <h4>{numberOfItems} Item{numberOfItems !== 1 ? 's' : ''}</h4>
      <div className="menu-cart-mobile">
        <Icon
          className={mobileCartOpen ? 'flip' : ''}
          name="angle up"
          size="large"
          onClick={onToggleMobileCart}
        />
      </div>
    </div>
  )
};

export default MenuCartDisplayBanner