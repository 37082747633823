import React from 'react';
import ContactForm from './ContactForm';


export default (props) => {

  const { emailCallback, onSuccess } = props;

  return (
    <ContactForm
      buttonText={ "Complete Order" }
      centeredHeaders={ false }
      className={ "" }
      fluidButton={ true }
      header={ "" }
      inline={ false }
      onSuccess={ onSuccess }
      onSubmit={ emailCallback }
      showLabels={ true }
      subheader={ "" }
      termsCopy={ "" }
      withContainer={ true }
      fields={
        [
          {
            "label":"Full name",
            "placeholder":"Full name",
            "type":"input",
            "required":true
          },
          {
            "label":"phone",
            "placeholder":"Phone",
            "type":"input",
            "required":true
          },
          {
            "label":"email",
            "placeholder":"Email",
            "type":"input",
            "inputType":"email",
            "required":true
          },
          {
            "label":"Date of event",
            "placeholder":"Date of event",
            "type":"input",
            "inputType":"date",
            "required":true
          },
          {
            "label":"message",
            "placeholder":"Additional comments",
            "type":"textarea",
            "required":false
          }
        ]
      }
    />
  )
}
