
export const generateLocationArray = (menu) => menu.locations.map(loc => {
    const val = loc.name + " (" + loc.address.street + ", " + loc.address.city + ", " + loc.address.state + ")";
    return { text: val, value: loc.id, email: loc.email }
  });

export const kfcHours = [
  {
    day: 0,
    open: null,
    close: null,
    label: 'Monday',
  },
  {
    day: 1,
    open: null,
    close: null,
    label: 'Monday',
  },
  {
    day: 2,
    open: null,
    close: null,
    label: 'Tuesday',
  },
  {
    day: 3,
    open: null,
    close: null,
    label: 'Wednesday',
  },
  {
    day: 4,
    open: null,
    close: null,
    label: 'Thursday',
  },
  {
    day: 5,
    open: null,
    close: null,
    label: 'Friday',
  },
  {
    day: 6,
    open: null,
    close: null,
    label: 'Saturday',
  },
  {
    day: 7,
    open: null,
    close: null,
    label: 'Sunday',
  },
];

