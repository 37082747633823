import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import './MenuCartDisplay.css';
import MenuCartDisplayBanner from './MenuCartDisplayBanner';
import MenuCartDisplayTotal from './MenuCartDisplayTotal';
import MenuCartDisplayItem from './MenuCartDisplayItem';
import ClosedIcon from './closed.svg';


export default class MenuCartDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileCartOpen: false
    };

    this.toggleMobileCart = this.toggleMobileCart.bind(this);
    this.calculateItemTotal = this.calculateItemTotal.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);
  }

  toggleMobileCart() {
    this.setState(({ mobileCartOpen }) => ({ mobileCartOpen: !mobileCartOpen }));
  }

  calculateItemTotal(item) {
    const { selectedVariation, selectedModifiers } = item;

    const { price: variationPrice = 0.0 } = selectedVariation;
    const modifierPrices = selectedModifiers.map(({ price }) => price);
    const modifiersTotal = modifierPrices.reduce((total, nextPrice)=>total + nextPrice, 0.0);
    const total = modifiersTotal + variationPrice;

    return total;
  }

  calculateTotal() {
    const { cart } = this.props;
    const { items } = cart;

    return items.reduce((total, item)=>total + (this.calculateItemTotal(item) * item.quantity), 0.0);
  }

  render() {
    const { cart, menu, onCheckout, locationId } = this.props;
    const { isOpen } = cart;
    const { mobileCartOpen } = this.state;
    const { items, removeItem } = cart;
    const { modifierSets, modifiers } = menu;

    return (
      <div className="menu-cart-display">
        <MenuCartDisplayBanner
          mobileCartOpen={mobileCartOpen}
          onToggleMobileCart={this.toggleMobileCart}
          numberOfItems={items.length}
        />
        <div className={`menu-cart-items ${mobileCartOpen ? 'open' : 'closed'}`}>
          {items.length > 0 ? (
            items.map((item, index)=>(
              <MenuCartDisplayItem
                cart={cart}
                key={item.id}
                item={item}
                onRemoveItem={()=>removeItem(index)}
                totalPrice={this.calculateItemTotal(item)}
                allModifierSets={modifierSets}
                allModifiers={modifiers}
              />
            ))
          ) : isOpen ? (
            <div className="no-items">
              <Icon size="huge" name="shopping basket"/>
              <p>Add at least 1 item to your cart to checkout</p>
            </div>
          ) : (
            <div className="no-items">
              <img src={ClosedIcon} style={{ height: '96px' }}/>
              <p>We are closed right now. Please come back later.</p>
            </div>
          )}
        </div>
        <MenuCartDisplayTotal
          isOpen={isOpen}
          onCheckout={onCheckout}
          total={this.calculateTotal()}
          cart={cart}
          locationId={locationId}
        />
      </div>
    );
  }
};

MenuCartDisplay.propTypes = {
  hours: PropTypes.object,
  onCheckout: PropTypes.func,
  locationId: PropTypes.number.isRequired,
  cart: PropTypes.shape({
    items: PropTypes.array,
    addItem: PropTypes.func,
    removeItem: PropTypes.func,
    updateItem: PropTypes.func,
    containsItem: PropTypes.func,
    clearCart: PropTypes.func,
    getItemIndex: PropTypes.func
  }),
  menu: PropTypes.shape({
    schedules: PropTypes.array,
    categories: PropTypes.array,
    items: PropTypes.array,
    modifierSets: PropTypes.array,
    modifiers: PropTypes.array,
  }).isRequired
};
