/* eslint-disable react/destructuring-assignment, class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, TextArea, Button, Header, Checkbox, Container, Modal } from 'semantic-ui-react';

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.createInitialState();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearError = this.clearError.bind(this);
  }

  toUpperCase(label) {
    return label.slice(0, 1).toUpperCase() + label.slice(1);
  }

  createFieldGroups(fields) {
    return fields.reduce((acc, _, i) => (i % 2 ? acc : [...acc, fields.slice(i, i + 2)]), []);
  }

  createInitialState() {
    const { fields, termsCopy } = this.props;

    const formFieldState = fields.reduce((acc, { label, value }) => ({ ...acc, [label]: value }), {});
    const additionalState = {
      hasSubmitted: false,
      hasError: false,
      errorMessage: null,
      loading: false,
    };
    const acceptTermsState = termsCopy ? { acceptedTerms: false } : {};

    return { ...formFieldState, ...additionalState, ...acceptTermsState };
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  clearError() {
    this.setState({hasError: false, errorMessage: null});
  }

  areAllRequiredFieldsFilled() {
    const { hasSubmitted, hasError, ...theRest } = this.state;
    const { fields } = this.props;

    return fields.filter(field => {
      if (!field.required) {
        return false;
      }
      if (!theRest[field.label]) {
        return true;
      }
      return false;
    }).length === 0;;
  }

  handleSubmit() {
    const { hasSubmitted, hasError, ...theRest } = this.state;
    const { onSubmit, onSuccess } = this.props;

    if (this.areAllRequiredFieldsFilled()) {
      this.setState({ loading: true },
        () => {
          const x = onSubmit(theRest);
          x.then(() => {
            this.setState({ loading: false });
            if (onSuccess) {
              onSuccess();
            }
          })
          .catch((e) => {
            this.setState(
            {
              hasError: true,
              loading: false,
              errorMessage: "Unknown error sending contact form. Please try again later."
            })
          })
        }
      );

    } else {
      this.setState({
        hasError: true,
        loading: false,
        errorMessage: "Please fill in all required fields!"
      });
    }
  }

  mapFieldToInput(field) {
    const { showLabels } = this.props;
    const { label, placeholder, type, required, options } = field;
    const value = this.state[label];

    if (type === 'hidden') {

      // No UI element.
      return null;

    } else if (type === 'select') {

      const updatedOptions = options.map((option) => {
        return {
          key: option.value,
          value: option.value,
          text: option.label,
        }
      });

      return (
        <Form.Select
          required={required}
          key={label}
          name={label}
          label={showLabels ? this.toUpperCase(label) : ''}
          placeholder={placeholder}
          value={value}
          labeled={false}
          options={updatedOptions}
          onChange={this.handleChange}
        />
      );

    } else {

        return (
        <Form.Field
          required={required}
          key={label}
          name={label}
          label={showLabels ? this.toUpperCase(label) : ''}
          placeholder={placeholder}
          control={type === 'input' ? Input : TextArea}
          value={this.state[label]}
          onChange={this.handleChange}
        />
      );
    }
  }

  mapFieldsToInputGroup(fields) {
    return (
      <Form.Group widths="equal" key={fields[0].label}>
        {fields.map(field => this.mapFieldToInput(field))}
      </Form.Group>
    );
  }

  renderForms() {
    const { fields, inline } = this.props;

    // Create chunks of two forms per group
    const fieldGroups = this.createFieldGroups(fields);

    // Wrap fields with form components
    const formGroups = fieldGroups.map(fieldGroup => this.mapFieldsToInputGroup(fieldGroup));
    const forms = fields.map(field => this.mapFieldToInput(field));

    const fieldsWithForms = inline ? formGroups : forms;

    return <React.Fragment>{fieldsWithForms}</React.Fragment>;
  }

  renderTOS() {
    const { termsCopy } = this.props;
    const { acceptTerms } = this.state;

    return (
      termsCopy && <Form.Field control={Checkbox} label={termsCopy} value={acceptTerms} onChange={this.handleChange} />
    );
  }

  renderHeader() {
    const { header, subheader, centeredHeaders } = this.props;

    return (
      <React.Fragment>
        {header && (
          <Header as="h1" textAlign={centeredHeaders ? 'center' : 'left'}>
            {header}
          </Header>
        )}
        {subheader && (
          <Header as="h3" textAlign={centeredHeaders ? 'center' : 'left'}>
            {subheader}
          </Header>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { buttonText, className, fluidButton, withContainer, button } = this.props;
    const { loading, hasError, errorMessage } = this.state;

    const form = (
      <div className={className}>
        <Form loading={loading}>
          {this.renderHeader()}
          {this.renderForms()}
          {this.renderTOS()}
          {button && (
            <Button onClick={this.handleSubmit} primary fluid={fluidButton}>
              {buttonText}
            </Button>
          )}
        </Form>
        <Modal
          open={hasError}
          size="mini"
          dimmer="blurring"
          onClose={this.clearError}
          closeIcon
        >
          <Modal.Header>Error!</Modal.Header>
          <Modal.Content>
            { errorMessage }
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.clearError}>OK</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );

    return withContainer ? <Container>{form}</Container> : { form };
  }
}

ContactForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      placeholder: PropTypes.string,
      type: PropTypes.oneOf(['input', 'textarea', 'select']),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string
        })
      )
    }),
  ),
  buttonText: PropTypes.string,
  centeredHeaders: PropTypes.bool,
  className: PropTypes.string,
  fluidButton: PropTypes.bool,
  button: PropTypes.bool,
  header: PropTypes.string,
  inline: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  showLabels: PropTypes.bool,
  subheader: PropTypes.string,
  termsCopy: PropTypes.string,
  withContainer: PropTypes.bool,
};

ContactForm.defaultProps = {
  fields: [
    {
      label: 'name',
      placeholder: 'Name',
      type: 'input',
      required: true,
    },
    {
      label: 'email',
      placeholder: 'you@gmail.com',
      type: 'input',
      required: true,
    },
    {
      label: 'message',
      placeholder: 'Message',
      type: 'textarea',
      required: false,
    },
  ],
  buttonText: 'Submit',
  centeredHeaders: false,
  className: '',
  fluidButton: false,
  button: true,
  header: '',
  inline: false,
  showLabels: false,
  subheader: '',
  termsCopy: '',
  withContainer: true,
};

ContactForm.propOptions = {
  header: ['Contact Form', 'Contact Us'],
  termsCopy: ['Accept the Terms of Service', ''],
};

ContactForm.dataPropsMap = {};
