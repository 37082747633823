const MenuUtil = {
    getScheduleCategories(scheduleCategories = [], categories) {
      return MenuUtil.getLinkedEntities(scheduleCategories, categories);
    },
  
    getCategoryItems(categoryItems, items) {
      return MenuUtil.getLinkedEntities(categoryItems, items);
    },
  
    getItemModifierSets(itemModifierSets, modifierSets) {
      return MenuUtil.getLinkedEntities(itemModifierSets, modifierSets);
    },
  
    getModifierSetModifiers(modifierSetModifiers, modifiers) {
      return MenuUtil.getLinkedEntities(modifierSetModifiers, modifiers);
    },
  
    getScheduleById(schedules, scheduleId) {
      return schedules.filter(({ id }) => scheduleId === id)[0];
    },
  
    getCategoryById(categories, category) {
      return categories.filter(({ id }) => category === id)[0];
    },
  
    getItemById(items, itemId) {
      return items.filter(({ id }) => itemId === id)[0];
    },
  
    getModifierSetById(modifierSets, modifierSetId) {
      return modifierSets.filter(({ id }) => modifierSetId === id)[0];
    },
  
    getMenuItemVariations(item) {
      const { variations } = item;
      return variations;
    },
  
    menuItemHasOneVariation(item) {
      const variations = MenuUtil.getMenuItemVariations(item);
      return variations.length === 1;
    },
  
    getLinkedEntities(entityIds, entitiesToLink) {
      if (!entitiesToLink || !entitiesToLink.length) {
        return [];
      }
  
      return entitiesToLink.filter(({ id }) => entityIds.includes(id));
    },
  };
  
  export default MenuUtil;
  