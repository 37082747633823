import React from 'react';
import { graphql } from 'gatsby';
import { Grid } from 'semantic-ui-react';
import Helmet from 'react-helmet';
import axios from 'axios';

import { PageLayout } from '@fishrmn/fishrmn-components';
import KfcCheckout from '../components/fisherman/KfcCheckout';
import Layout from '../components/Layout';
import { generateLocationArray } from '../components/fisherman/KfcUtils';

const getServerURL = () => {
    return 'https://api.gofisherman.com';
};

function emailCallback(fields) {
    const businessType = 'restaurants';
    const businessId = '1867';
    const name = fields['Full name'];
    const phone = fields['phone'];
    const email = fields['email'];
    const date = fields['Date of event'];
    const locationId = fields['selectedLocationId'];
    const message = fields['message'] || 'None';
    const orderDetails = fields['Order details'];

    const url = getServerURL();
    const fullUrl = `${url}/businesses/${businessType}/${businessId}/locations/${locationId}/catering/`;

    return axios.post(fullUrl, {
        contact_name: name,
        contact_phone: phone,
        contact_email: email,
        event_date: date,
        message,
        order_details: orderDetails,
    });
}

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    const kfcLocationArray = generateLocationArray(data);

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Online ordering</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={ null }
          other={ null }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={ "middle" }
            textAlign={ "left" }
          >
            <Grid.Column width={ 16 }>
              <KfcCheckout
                menu={{
                  schedules: data.menu.schedules,
                  categories: data.menu.categories,
                  items: data.menu.items,
                  modifierSets: data.menu.modifier_sets,
                  modifiers: data.menu.modifiers
                }}
                locations={kfcLocationArray}
                hours={[]}
                emailCallback={emailCallback}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          locations {
            address {
              city
              state
              street
              zip
            }
            id
            phoneNumber
            primaryLocation
            timezone
            email
            name
          }
          menu {
            categories {
              items
              name
              id
            }
            items {
              modifier_sets
              description
              id
              name
              variations {
                id
                menu_item
                name
                order
                price
              }
            }
            modifier_sets {
              id
              min_allowed
              modifiers
              name
            }
            modifiers {
              id
              name
              price
            }
            schedules {
              available_days
              categories
              id
              name
            }
          }
          businessName
          about
          reviews {
            name 
            content 
            link 
          }
          ctas {
            phone {
              link 
              text 
              internal 
            }
          }
          address {
            street 
            city 
            state 
            zip 
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url 
          }
          heroImages {
            url 
          }
        }
      }
    }
  }
`;
