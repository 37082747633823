import React from 'react';
import _ from 'lodash';
import { Grid, Button, Header, Icon } from 'semantic-ui-react';
import CartHOC from './CartHOC.jsx';
import FoodMenu from '../fisherman/FoodMenu';
import MenuCartDisplay from './MenuCartDisplay.jsx';
import KfcContactForm from './KfcContactForm.jsx';
import './KfcCheckout.css';
import GeoClosestLocationSelect from '../GeoClosestLocationSelect.jsx';


export default class extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      checkoutStep: 0,
      selectedLocationId: null,
      cartItems: [],
    }

    this.sendContactForm = this.sendContactForm.bind(this);
    this.changeCheckoutStep = this.changeCheckoutStep.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
  }

  async sendContactForm(fields) {
    const { cartItems, selectedLocationId } = this.state;
    const { emailCallback, locations } = this.props;
    const selectedLocation = _.find(locations, l => l.value === selectedLocationId);

    fields['locationEmail'] = selectedLocation.email;
    fields['Selected location'] = selectedLocation.text;
    fields['selectedLocationId'] = selectedLocationId;
    fields['Order details'] = CartHOC.generateHumanReadableListing(cartItems);

    return await emailCallback(fields);
  }

  changeCheckoutStep(s = 1) {
    const { checkoutStep } = this.state;

    if (s < 0 && checkoutStep === 1) {
      this.setState({ selectedLocationId: null });
    }

    this.setState({ checkoutStep: checkoutStep + s });
  }

  /**
   * Is expecting a dropdown item with:
   *
   * loc.value -> the backend location id
   * loc.text
   * loc.email
   *
   */
  selectLocation(loc) {
    if (loc) {
      this.setState({selectedLocationId: loc.value})
    } else {
      this.setState({selectedLocationId: null})
    }
  }

  doCheckout(cart) {
    this.changeCheckoutStep();
    this.setState({cartItems: cart.items})
  }

  renderBottomButtons() {
    const { selectedLocationId } = this.state;
    let nextStepButtonState = 'hidden';
    nextStepButtonState = selectedLocationId ? 'enabled' : 'disabled';
  }

  renderTopHeader() {
    const { checkoutStep, selectedLocationId } = this.state;

    if (checkoutStep === 0 || checkoutStep >= 4) {
        return null;
    }

    let message;

    switch (checkoutStep) {
      case 1:
        message = "Select your preferred location to receive your order.";
        break;
      case 2:
        message = "Click the items in the menu below to add them to your cart.";
        break;
      case 3:
        message = "Enter your contact info in the form.";
        break;
      case 4:
      default:
        message = "";
        break;
    }

    return (
      <Grid verticalAlign="middle" textAlign="center">
        <Grid.Column width={2}>
        </Grid.Column>
        <Grid.Column width={12}>
          <Header as="h3">{ message }</Header>
        </Grid.Column>
        <Grid.Column width={2} />
      </Grid>
    );
  }

  renderSuccess() {
    window.scrollTo({top: 400, behavior: 'smooth'});
    return (
      <div style={{textAlign: 'center'}}>
        <h1>Success!</h1>
        <p>You have successfully sent your catering order. We will contact you within 24 hours to review and confirm the details of your order.</p>
      </div>
    );
  }

  renderContactForm() {
    const { locations } = this.props;
    window.scrollTo({top: 50, behavior: 'smooth'});
    return (
        <>
            <KfcContactForm locations={locations} emailCallback={this.sendContactForm} onSuccess={() => this.changeCheckoutStep()} />
            <Grid>
                <Grid.Column textAlign="center">
                    <Button icon='left arrow' onClick={() => this.changeCheckoutStep(-1)}>Previous Step</Button>
                </Grid.Column>
            </Grid>
        </>
    );
  }

  renderMenu() {
    const { menu, hours } = this.props;
    const { cartItems } = this.state;

    return (
      <>
        <CartHOC hours={hours} items={cartItems}>
          { cart => (
            <div className="food-menu-cart-story">
              {
                (cart.items.length > 0) && <div id="food-menu-cart-floating-total">
                  <p><Icon name="shopping cart" />{ (() => { const total = _.sumBy(cart.items, i => i.quantity); return total === 1 ? '1 item' : total + ' items'; } )() }</p>
                  <p style={{lineHeight: '12px', marginTop: '8px'}}>Scroll down<br/>to checkout!</p>
                </div>
              }
              <FoodMenu
                header={null}
                menu={menu}
                cart={cart}
                centerNavigation
              />
              <MenuCartDisplay
                cart={cart}
                menu={menu}
                locationId={173}
                onCheckout={ () => this.doCheckout(cart) }
              />
            </div>
          )}
        </CartHOC>

        <Grid>
          <Grid.Column textAlign="center">
              <Button icon='left arrow' onClick={() => this.changeCheckoutStep(-1)}>Previous Step</Button>
          </Grid.Column>
        </Grid>
      </>
    );
  }


  renderInstructions() {
    const { locations } = this.props;

    return (
      <>
      <Header>Your event starts here!</Header>
      <p>Follow these four easy steps to have KFC at your party or event.</p>
      <ol>
          <li>We'll use your location to find the KFC closest to you, or you can pick from our list of { locations.length } locations.</li>
          <li>Click the items in the menu to add them to your cart.</li>
          <li>Enter your contact info in the form.</li>
          <li>We'll contact you with you order confirmation.</li>
      </ol>
      <Button className="kfc" onClick={() => this.changeCheckoutStep()}>Start!</Button>
      <p style={{color: 'gray', fontSize: 'smaller', marginTop: '30px'}}>Orders Require 72 Hours Advance Notice - If your event is within the 72 hour window please call the restaurant directly so we can help you with your catering needs.</p>
      </>
    )
  }


  renderLocationSelect() {
    const { locations } = this.props;
    const { selectedLocationId } = this.state;

    return (
      <>

        <GeoClosestLocationSelect
          selectedLocationId={selectedLocationId}
          locations={locations}
          maxDistanceMiles="20"
          onSelectLocation={this.selectLocation}
        />

        <Grid>
          <Grid.Column textAlign="center">
          <Button icon='left arrow' onClick={() => this.changeCheckoutStep(-1)}>Previous Step</Button>
            <Button
              className="kfc"
              icon='right arrow'
              disabled={ !selectedLocationId }
              onClick={() => this.changeCheckoutStep()}
            >
              Next Step
            </Button>
          </Grid.Column>
        </Grid>
      </>
    );
  }

  render() {
    const { checkoutStep } = this.state;
    let checkoutView;

    switch (checkoutStep) {
      case 0:
        checkoutView = this.renderInstructions();
        break;
      case 1:
        checkoutView = this.renderLocationSelect()
        break;
      case 2:
        checkoutView = this.renderMenu()
        break;
      case 3:
        checkoutView = this.renderContactForm()
        break;
      case 4:
      default:
        checkoutView = this.renderSuccess()
        break;
    }

    return <>{ this.renderTopHeader() }{ checkoutView }</>
  }

}
