import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

export default class CartHOC extends React.Component {

  static generateHumanReadableListing(cartItems, asHtml = true) {

    const utils = {
      tag: (text, as) => asHtml ? `<${as}>${text}</${as}>` : `${text}\n`,
      list: (items, key) => {
        let str = asHtml ? '<ul>' : ' - ';
        str += items.reduce((accum, item) => accum + (asHtml ? `<li>${item[key]}</li>` : ` - ${item[key]}\n`), '');
        str += "</ul>";
        return str;
      }
    }

    let str = '';
    cartItems.forEach(item => {
      str += utils.tag(item.name, 'h1');
      str += utils.tag(item.description, 'p');

      str += utils.tag("Quantity", 'h2');
      str += utils.tag(item.quantity, 'p');

      str += utils.tag("Variation", 'h2');
      str += utils.tag(item.selectedVariation.name + " ($" + item.selectedVariation.price + ")", 'p');

      if (item.selectedModifiers && item.selectedModifiers.length > 0) {
        str += utils.tag("Modifiers", 'h2');
        str += utils.list(item.selectedModifiers, 'name');
      }

      if (item.specialInstructions) {
        str += utils.tag("Special Instructions", 'h2');
        str += utils.tag(item.specialInstructions, 'p');
      }

      str += "\n\n<br /><br />";
    });

    return str;
  }


  static normalizeHour(hour) {
    const hourAsString = hour.toString();

    if (!hourAsString) return null;
    if (hourAsString.length === 2) return `00${hourAsString}`;
    if (hourAsString.length === 3) return `0${hourAsString}`;
    return hourAsString;
  }

  constructor(props) {
    super(props);

    this.state = {
      items: props.items,
    };

    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.containsItem = this.containsItem.bind(this);
    this.clearCart = this.clearCart.bind(this);
    this.getItemIndex = this.getItemIndex.bind(this);
    this.isOpen = this.isOpen.bind(this);
  }

  getItemIndex(item, lookupFunc = key => key) {
    const { items } = this.state;
    return items.map(aItem => lookupFunc(aItem)).indexOf(lookupFunc(item));
  }

  addItem(item) {
    this.setState(({ items }) => ({ items: [...items, item] }));
  }

  removeItem(index) {
    this.setState(({ items }) => ({
      items: [...items.slice(0, index), ...items.slice(index + 1, items.length)],
    }));
  }

  updateItem(item, index) {
    this.setState(({ items }) => ({
      items: [...items.slice(0, index), item, ...items.slice(index + 1, items.length)],
    }));
  }

  containsItem(item, comparisionFunc = key => key) {
    const { items } = this.state;
    return items.map(aItem => comparisionFunc(aItem)).includes(comparisionFunc(item));
  }

  clearCart() {
    this.setState({ items: [] });
  }

  isOpen() {
    const { hours = [], timezone = 'America/New_York' } = this.props;

    // Backend stores weeks starting on Monday, but
    // moment starts on Sunday.
    const translateDay = {
      7: 0, // Sunday -> Monday
      1: 1, // Monday -> Tuesday
      2: 2, // Tuesday -> Wednesday
      3: 3, // Wednesday -> Thursday
      4: 4, // Thursday -> Friday
      5: 5, // Friday -> Saturday
      6: 6, // Saturday -> Sunday
    };

    // Find the relevant day...
    const today = moment.tz(timezone).day();
    const todaysHours = hours.filter(i => translateDay[i.day] === today)[0];
    const thisHour = moment.tz(timezone).hour();
    const thisMinute = moment.tz(timezone).minute();

    // Convert open and closed hours
    const convertTime = timeToConvert => {
      if (!timeToConvert) return [];

      const formattedTime = CartHOC.normalizeHour(timeToConvert);
      const hour = formattedTime.substring(0, 2);
      const minute = formattedTime.substring(2, 4);

      if (hour === '24') {
        return [parseInt('00', 10), parseInt(minute, 10)];
      }
      return [parseInt(hour, 10), parseInt(minute, 10)];
    };

    const compareTimes = (open, close) => {
      if (!open || !close) return false;

      const afterOpen = () => {
        if (thisHour > open[0]) return true;
        if (thisHour === open[0]) return thisMinute >= open[1];
        return false;
      };

      const beforeClose = () => {
        if (thisHour < close[0]) return true;
        if (thisHour === close[0]) return thisMinute <= close[1];
        return false;
      };
      return afterOpen() && beforeClose();
    };

    if (todaysHours) {
      const todayOpen = todaysHours.open;
      if (todayOpen) {
        const convertedOpen = convertTime(todayOpen);
        const todayClose = todaysHours.close;
        if (todayClose) {
          const convertedClose = convertTime(todayClose);
          return compareTimes(convertedOpen, convertedClose);
        }
      }
    }

    return false;
  }

  render() {
    const { children } = this.props;
    const { items } = this.state;

    return children({
      items,
      isOpen: true, //this.isOpen(),
      addItem: this.addItem,
      removeItem: this.removeItem,
      updateItem: this.updateItem,
      containsItem: this.containsItem,
      clearCart: this.clearCart,
      getItemIndex: this.getItemIndex,
    });
  }
}

CartHOC.propTypes = {
  children: PropTypes.func,
};

CartHOC.defaultProps = {
  children: () => null,
};
