import React from 'react';
import { Button } from 'semantic-ui-react';

const MenuCartDisplayTotal = ({ total, onCheckout, cart, locationId, isOpen, totalTitle = 'Subtotal' }) => {
  return (
    <div className="menu-cart-total">
      <div>
        <h4>{totalTitle}</h4>
        <h4>${total.toFixed(2)}</h4>
      </div>
        <Button primary fluid onClick={onCheckout} disabled={total === 0 || !isOpen }>
          { isOpen ? "Checkout" : "Ordering Closed" }
        </Button>
    </div>
  )
};

export default MenuCartDisplayTotal;
