import React from 'react';
import { Label, Button } from 'semantic-ui-react';
import MenuUtil from './MenuUtil';
import MenuItemCartModal from '@fishrmn/fishrmn-components/build/components/organisms/MenuItemCartModal';


class MenuCartDisplayItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { modalOpen: false };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getModalOpen = this.getModalOpen.bind(this);
  }

  handleOpen() {
    this.setState({ modalOpen: true })
  }

  handleClose() {
    this.setState({ modalOpen: false })
  }

  getModalOpen() {
    return this.state.modalOpen
  }

  render() {
    const {
      cart,
      item,
      totalPrice,
      onRemoveItem,
      allModifierSets,
      allModifiers
    } = this.props;
    const {
      name,
      quantity,
      selectedVariation,
      selectedModifiers,
      specialInstructions,
      modifier_sets: itemModifierSetIds
    } = item;
    const itemModifierSets = MenuUtil.getItemModifierSets(itemModifierSetIds, allModifierSets);

    return (
      <div className="menu-cart-item">
        <div className="menu-cart-options">
          <div>
            {name}
          </div>
          <div style={{ fontWeight: 'bold'}}>
            {selectedVariation.name !== 'Regular' && selectedVariation.name}
          </div>
          {selectedModifiers.map(({ name: modifierName, price }) => (
            <div>
              {modifierName}{' '}{price !== 0 && `+$${price.toFixed(2)}`}
            </div>
          ))}
          { specialInstructions && (
            <div style={{ padding: '1rem 0'}}>
              Notes: {specialInstructions}
            </div>
          )}

          <MenuItemCartModal
            trigger={(
              <Button onClick={this.handleOpen} primary size="mini">
                EDIT
              </Button>
            )}
            type="update"
            open={this.getModalOpen()}
            onClose={this.handleClose}
            item={item}
            modifiers={allModifiers}
            modifierSets={itemModifierSets}
            variations={MenuUtil.getMenuItemVariations(item)}
            cart={cart}
            initialSelectedVariation={selectedVariation}
            initialSelectedModifiers={selectedModifiers}
            initialSpecialInstructions={specialInstructions}
            initialQuantity={quantity}
          />
          <Button onClick={onRemoveItem} basic size="mini">
            REMOVE
          </Button>
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            <Label>x{quantity}</Label>
          </div>
          <div>${totalPrice.toFixed(2)}</div>
        </div>
      </div>
    )
  }
}

export default MenuCartDisplayItem;